// extracted by mini-css-extract-plugin
export var featureBox = "Space-Planning-Software-module--featureBox--e9a6a";
export var featureContainer = "Space-Planning-Software-module--featureContainer--5c14b";
export var featureDesc = "Space-Planning-Software-module--featureDesc--89109";
export var featureGridCompWrapper = "Space-Planning-Software-module--featureGridCompWrapper--6d710";
export var featureGridDesc = "Space-Planning-Software-module--featureGridDesc--9ef0c";
export var featureGridTitle = "Space-Planning-Software-module--featureGridTitle--9b00b";
export var featureGridWrapper = "Space-Planning-Software-module--featureGridWrapper--72bf0";
export var featureHeadingWrapper = "Space-Planning-Software-module--featureHeadingWrapper--1bd32";
export var featureLogoWrapper = "Space-Planning-Software-module--featureLogoWrapper--83ee4";
export var featureProductsLogo = "Space-Planning-Software-module--featureProductsLogo--96292";
export var featureProductsLogoList = "Space-Planning-Software-module--featureProductsLogoList--605aa";
export var featureProductsLogoWrapper = "Space-Planning-Software-module--featureProductsLogoWrapper--a6c6f";
export var featureProductsText = "Space-Planning-Software-module--featureProductsText--66b9c";
export var featureProductsWrapper = "Space-Planning-Software-module--featureProductsWrapper--ca6f7";
export var featureTitle = "Space-Planning-Software-module--featureTitle--427b2";
export var featureWrapper = "Space-Planning-Software-module--featureWrapper--81e1c";
export var fullWidthBannerContainer = "Space-Planning-Software-module--fullWidthBannerContainer--f5643";
export var fullWidthBannerDesc = "Space-Planning-Software-module--fullWidthBannerDesc--5c757";
export var fullWidthBannerImage = "Space-Planning-Software-module--fullWidthBannerImage--13019";
export var fullWidthBannerLogo = "Space-Planning-Software-module--fullWidthBannerLogo--0707b";
export var fullWidthBannerLogoWrapper = "Space-Planning-Software-module--fullWidthBannerLogoWrapper--6a822";
export var fullWidthBannerName = "Space-Planning-Software-module--fullWidthBannerName--01a68";
export var fullWidthBannerTextWrapper = "Space-Planning-Software-module--fullWidthBannerTextWrapper--c9a38";
export var fullWidthBannerTitle = "Space-Planning-Software-module--fullWidthBannerTitle--0a043";
export var fullWidthBannerWrapper = "Space-Planning-Software-module--fullWidthBannerWrapper--edbfe";
export var hero1Desc = "Space-Planning-Software-module--hero1Desc--48b95";
export var hero1Title = "Space-Planning-Software-module--hero1Title--204ee";
export var heroBtn1 = "Space-Planning-Software-module--heroBtn1--87ac9";
export var heroBtn1Img = "Space-Planning-Software-module--heroBtn1Img--f791e";
export var heroBtn1Text = "Space-Planning-Software-module--heroBtn1Text--a1377";
export var heroBtn1Wrapper = "Space-Planning-Software-module--heroBtn1Wrapper--41df3";
export var heroBtn2Text = "Space-Planning-Software-module--heroBtn2Text--ffa71";
export var heroBtn2Wrapper = "Space-Planning-Software-module--heroBtn2Wrapper--e4f3a";
export var heroBtnWrapper = "Space-Planning-Software-module--heroBtnWrapper--eb83e";
export var heroContainer = "Space-Planning-Software-module--heroContainer--e9d86";
export var heroContainer1 = "Space-Planning-Software-module--heroContainer1--04694";
export var heroDesc = "Space-Planning-Software-module--heroDesc--c2f17";
export var heroImage = "Space-Planning-Software-module--heroImage--8557e";
export var heroTitle = "Space-Planning-Software-module--heroTitle--86e27";
export var leftWrapper = "Space-Planning-Software-module--leftWrapper--0eda9";
export var logoSliderContainer = "Space-Planning-Software-module--logoSliderContainer--19644";
export var logoSliderImage = "Space-Planning-Software-module--logoSliderImage--e6211";
export var logoStrip = "Space-Planning-Software-module--logoStrip--00bd2";
export var resourceCard = "Space-Planning-Software-module--resourceCard--06d02";
export var resourceCardBtn = "Space-Planning-Software-module--resourceCardBtn--34a76";
export var resourceCardBtnWrapper = "Space-Planning-Software-module--resourceCardBtnWrapper--1013c";
export var resourceCardImg = "Space-Planning-Software-module--resourceCardImg--54dc5";
export var resourceCardTextWrapper = "Space-Planning-Software-module--resourceCardTextWrapper--401d7";
export var resourceCardTitle = "Space-Planning-Software-module--resourceCardTitle--7d9ca";
export var resourceCardWrapper = "Space-Planning-Software-module--resourceCardWrapper--06d69";
export var resourceContainer = "Space-Planning-Software-module--resourceContainer--e7d46";
export var resourceHeadingWrapper = "Space-Planning-Software-module--resourceHeadingWrapper--942f3";
export var resourceLinkText = "Space-Planning-Software-module--resourceLinkText--cb4c1";
export var resourceLinkWrapper = "Space-Planning-Software-module--resourceLinkWrapper--6ecc9";
export var resourceListCardBig = "Space-Planning-Software-module--resourceListCardBig--270f6";
export var resourceMultiCard = "Space-Planning-Software-module--resourceMultiCard--5b10c";
export var resourceMultiCardDesc = "Space-Planning-Software-module--resourceMultiCardDesc--cd237";
export var resourceMultiCardImg = "Space-Planning-Software-module--resourceMultiCardImg--abaa9";
export var resourceMultiCardText = "Space-Planning-Software-module--resourceMultiCardText--a8075";
export var resourceMultiCardTextWrapper = "Space-Planning-Software-module--resourceMultiCardTextWrapper--84bb2";
export var resourceMultiCardWrapper = "Space-Planning-Software-module--resourceMultiCardWrapper--cab6f";
export var resourceTitle = "Space-Planning-Software-module--resourceTitle--0ea99";
export var resourceWrapper = "Space-Planning-Software-module--resourceWrapper--437f7";
export var serviceCardContainer = "Space-Planning-Software-module--serviceCardContainer--b651f";
export var serviceCardDesc = "Space-Planning-Software-module--serviceCardDesc--b9572";
export var serviceCardLinkText = "Space-Planning-Software-module--serviceCardLinkText--545d6";
export var serviceCardLinkWrapper = "Space-Planning-Software-module--serviceCardLinkWrapper--f5917";
export var serviceCardLogo = "Space-Planning-Software-module--serviceCardLogo--fed0a";
export var serviceCardLogoWrapper = "Space-Planning-Software-module--serviceCardLogoWrapper--1d501";
export var serviceCardText = "Space-Planning-Software-module--serviceCardText--cee2e";
export var serviceCardTextWrapper = "Space-Planning-Software-module--serviceCardTextWrapper--7c07c";
export var serviceCardTitle = "Space-Planning-Software-module--serviceCardTitle--18c00";
export var serviceCardWrapper = "Space-Planning-Software-module--serviceCardWrapper--d5d7a";
export var serviceContainer = "Space-Planning-Software-module--serviceContainer--07870";
export var serviceDesc = "Space-Planning-Software-module--serviceDesc--40843";
export var serviceHeadWrapper = "Space-Planning-Software-module--serviceHeadWrapper--36024";
export var serviceTitle = "Space-Planning-Software-module--serviceTitle--33223";
export var supportContactLink = "Space-Planning-Software-module--supportContactLink--41090";
export var supportContactLinkText = "Space-Planning-Software-module--supportContactLinkText--7b4ec";
export var supportContactText = "Space-Planning-Software-module--supportContactText--d6f65";
export var supportContactWrapper = "Space-Planning-Software-module--supportContactWrapper--a3306";
export var supportContainer = "Space-Planning-Software-module--supportContainer--b0cef";
export var supportHeadWrapper = "Space-Planning-Software-module--supportHeadWrapper--a0ec2";
export var supportListDesc = "Space-Planning-Software-module--supportListDesc--7cdf4";
export var supportListDescWrapper = "Space-Planning-Software-module--supportListDescWrapper--021fb";
export var supportListTitle = "Space-Planning-Software-module--supportListTitle--51bff";
export var supportListTitleWrapper = "Space-Planning-Software-module--supportListTitleWrapper--e7388";
export var supportListWrapper = "Space-Planning-Software-module--supportListWrapper--669e7";
export var supportQListWrapper = "Space-Planning-Software-module--supportQListWrapper--96ab2";
export var supportTitle = "Space-Planning-Software-module--supportTitle--131c8";
export var threeColContainer = "Space-Planning-Software-module--threeColContainer--9aa8d";
export var threeColDesc = "Space-Planning-Software-module--threeColDesc--7fa17";
export var threeColGrid = "Space-Planning-Software-module--threeColGrid--aeed5";
export var threeColGridBox = "Space-Planning-Software-module--threeColGridBox--c97c7";
export var threeColGridDesc = "Space-Planning-Software-module--threeColGridDesc--28282";
export var threeColGridTitle = "Space-Planning-Software-module--threeColGridTitle--52acf";
export var threeColGridWrapper = "Space-Planning-Software-module--threeColGridWrapper--426a8";
export var threeColHeadWrapper = "Space-Planning-Software-module--threeColHeadWrapper--10a3a";
export var threeColLogoWrapper = "Space-Planning-Software-module--threeColLogoWrapper--2ca83";
export var threeColTitle = "Space-Planning-Software-module--threeColTitle--6267a";
export var twoColCompCardDesc = "Space-Planning-Software-module--twoColCompCardDesc--a3686";
export var twoColCompCardLink = "Space-Planning-Software-module--twoColCompCardLink--f0a4c";
export var twoColCompCardLinkText = "Space-Planning-Software-module--twoColCompCardLinkText--4fd65";
export var twoColCompCardLogoWrapper = "Space-Planning-Software-module--twoColCompCardLogoWrapper--25b72";
export var twoColCompCardTextWrapper = "Space-Planning-Software-module--twoColCompCardTextWrapper--ed127";
export var twoColCompCardTitle = "Space-Planning-Software-module--twoColCompCardTitle--b85f7";
export var twoColCompCardWrapper = "Space-Planning-Software-module--twoColCompCardWrapper--1b881";
export var twoColCompContainer = "Space-Planning-Software-module--twoColCompContainer--e2837";
export var twoColCompDesc = "Space-Planning-Software-module--twoColCompDesc--7b9c9";
export var twoColCompLeftWrapper = "Space-Planning-Software-module--twoColCompLeftWrapper--58fec";
export var twoColCompLinkText = "Space-Planning-Software-module--twoColCompLinkText--ebbc4";
export var twoColCompLinkWrapper = "Space-Planning-Software-module--twoColCompLinkWrapper--34b7e";
export var twoColCompRightWrapper = "Space-Planning-Software-module--twoColCompRightWrapper--195d6";
export var twoColCompTextWrapper = "Space-Planning-Software-module--twoColCompTextWrapper--ac39c";
export var twoColCompTitle = "Space-Planning-Software-module--twoColCompTitle--b18e0";
export var twoColContainer = "Space-Planning-Software-module--twoColContainer--c11bf";
export var twoColGridLink = "Space-Planning-Software-module--twoColGridLink--7476b";
export var twoColGridWrapper = "Space-Planning-Software-module--twoColGridWrapper--617e1";
export var twoColGriditemBox = "Space-Planning-Software-module--twoColGriditemBox--3bafd";
export var twoColTitle = "Space-Planning-Software-module--twoColTitle--a99ee";
export var twocColGridContent = "Space-Planning-Software-module--twocColGridContent--a2baf";
export var twocColGridContentWrapper = "Space-Planning-Software-module--twocColGridContentWrapper--8714e";
export var twocColGridDesc = "Space-Planning-Software-module--twocColGridDesc--ca60a";
export var twocColGridDescList = "Space-Planning-Software-module--twocColGridDescList--fa563";
export var twocColGridImage = "Space-Planning-Software-module--twocColGridImage--ed15c";
export var twocColGridTitle = "Space-Planning-Software-module--twocColGridTitle--44107";
export var walkthroughCardDesc = "Space-Planning-Software-module--walkthroughCardDesc--c17fc";
export var walkthroughCardText = "Space-Planning-Software-module--walkthroughCardText--0373b";
export var walkthroughCardTitle = "Space-Planning-Software-module--walkthroughCardTitle--7613d";
export var walkthroughCardWrapper = "Space-Planning-Software-module--walkthroughCardWrapper--02784";
export var walkthroughFlexWrapper = "Space-Planning-Software-module--walkthroughFlexWrapper--a3f0a";
export var walkthroughTitle = "Space-Planning-Software-module--walkthroughTitle--ae1d4";
export var walkthroughWrapper = "Space-Planning-Software-module--walkthroughWrapper--3c9d2";