import React, {useState} from "react";
import * as styles from "src/styles/Space-Planning-Software.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';

import Dot from "../../../static/staticPages/Dot.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowRight from "../../../static/staticPages/arrowRight.svg"

import edmHero from "../../../static/staticPages/edm-hero.jpg"

import fileAltIcon from '../../../static/staticPages/file-alt.svg'
import fileCheckIcon from '../../../static/staticPages/file-check.svg'
import shieldCheckIcon from '../../../static/staticPages/shield-check.svg'
import usersIcon from '../../../static/staticPages/users.svg'
import phoneLaptopIcon from '../../../static/staticPages/phone-laptop.svg'

import lvvwdIcon from '../../../static/staticPages/lvvwd.png'

import businessTimeIcon from '../../../static/staticPages/business-time.svg'
import listCheckSolidIcon from '../../../static/staticPages/list-check-solid.svg'
import usersDrbIcon from '../../../static/staticPages/users-drb.svg'

import boltIcon from '../../../static/staticPages/bolt.svg'
import hardHatIcon from '../../../static/staticPages/hard-hat.svg'
import industryWindowsIcon from '../../../static/staticPages/industry-windows-solid.svg'

import featureProduct2 from "../../../static/staticPages/footer-famis-logo.png"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import spaceHero2 from "../../../static/staticPages/space-hero2.png"

import emsLogo from "../../../static/staticPages/ems-logo.svg"
import lucernexLogo from "../../../static/staticPages/lucernex-logo.svg"
import redeyeLogo from "../../../static/staticPages/Logo-Redeye-color.png"
import meridianLogo from '../../../static/staticPages/meridian-logo.png'
// import mcLogo from "../../../static/staticPages/mc-logo-new.png"
// import mcLogo from "src/images/new-version-homepage/Accruent-Maintenance-Connection-Color.png";
import mcLogo from "../../../static/staticPages/mc-logo.png"
import resource1 from "../../../static/staticPages/edmsres1.jpeg"
import resource2 from "../../../static/staticPages/edmsres2.avif"
import resource3 from "../../../static/staticPages/edmsres3.avif"
import resource4 from "../../../static/staticPages/edmsres4.avif"
import resource5 from "../../../static/staticPages/edmsres5.avif"
import twoCol1 from "../../../static/staticPages/twoCol1.png"
import twoCol2 from "../../../static/staticPages/twoCol2.png"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import buildingssolidicon from "../../../static/staticPages/buildings-solid.svg";
import graduationcapsolidicon from "../../../static/staticPages/graduation-cap-solid.svg";
import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";
import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";

import logo1 from '../../../static/staticPages/alinta-energy-logo-white.png'
import logo2 from '../../../static/staticPages/BMA-logo-white.png'
import logo3 from '../../../static/staticPages/Las-Vegas-Valley-Water-District-logo-white.png'
import logo4 from '../../../static/staticPages/radboudumc-logo-white.png'
import logo5 from '../../../static/staticPages/Senex-logo-white.png'
import logo6 from '../../../static/staticPages/Transurban-logo-white.png'
import logo7 from "../../../static/staticPages/Zurich-Airport-logo-white.png"

const data = {
  heroBanner:{
    title: 'Engineering Document Management Software',
    description:'Streamline your engineering teams with our EDMS solutions, designed for collaboration, version control, markups, and effortless sharing of accurate information. Available in both cloud-based and on-premises.',
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: edmHero,
      altText: 'Two men with hardhats looking at tablet Engineering document management software'
    }
  },
  features: {
    title: 'Unleash Best Practices with a Powerful EDMS',
    description: 'Easily store, access, and share the most up-to-date engineering drawings and information to enhance decision-making, security and safety, while eliminating costly inefficiency using the right engineering document management system.',
    items: [
      {
        logoUrl: fileCheckIcon,
        altText: "File check icon",
        title: "Access a Single Source of Truth Anywhere, Anytime",
        description: "Provide instant access to, accurate, up-to-date documents and drawings. Build trust, while reducing costs, downtime, and accidents."
      },
      {
        logoUrl: fileAltIcon,
        altText: "Files icon",
        title: "Streamline Document Change Management",
        description: "Streamline concurrent engineering and leverage automation to keep complex change management and document versions under control."
      },
      {
        logoUrl: usersIcon,
        altText: "User icon",
        title: "Foster Seamless Stakeholder Collaboration",
        description: "Eliminate silos between maintenance, engineering, and external contractors with easy collaboration in a common data environment."
      },
      {
        logoUrl: phoneLaptopIcon,
        altText: "Phone and laptop icon",
        title: "Connect Systems for Maximum Efficiency",
        description: "Extend the power of your engineering document management system (EDMS) by integrating it with other critical systems."
      },
      {
        logoUrl: shieldCheckIcon,
        altText: "Security check icon",
        title: "Keep Your Teams Safe",
        description: "Our RedEye and Meridian centralized repositories provide instant access to the most up-to-date drawings and documentation, which are critical to safety."
      },
      {
        logoUrl: fileAltIcon,
        altText: "File icon",
        title: "Access to Your Records Anytime",
        description: "Retrieve engineering information anywhere, anytime using our apps on iOS and Android, with offline access for sites with no internet."
      }
    ],
    info:{
      title: "Software to Optimize Your EDMS",
      images:[
        {
            url:redeyeLogo,
            altText: 'Accruent Redeye Logo',
            link:'/products/redeye'
        },
        {
            url: meridianLogo,
            altText:"Accruent Meridian logo",
            link:'/products/meridian'
        }
        ],
        link: {
        text: 'Request a demo',
        url:'/request-demo'
        }
    }
  },
  fullWidthBanner:{
    image: {
      url: lvvwdIcon,
      altText:"Las Vegas Valley Water District logo"
    },
    title:"Director of Infrastructure Management, Las Vegas Valley Water District",
    description: "“We went from 464,000 drawings down to 351,000 by removing direct duplicates, a 24% reduction, and time spent searching for drawings went down to two minutes. Our information is now quickly accessible.”",
    name: "Greg Kodweis",
    position: "Director of Infrastructure Management",
    // companyName: " Minnesota Colleges and Universities"
  },
  logoSlider:{
    logoList:[
      {
        url:logo1,
        altText:'Alinta Energy logo '
      },
      {
        url:logo2,
        altText:'BMA logo'
      },
      {
        url:logo3,
        altText:'Las Vegas Valley Water District logo'
      },
      {
        url:logo4,
        altText:'Radboudumc logo'
      },
      {
        url:logo5,
        altText:'Sensex logo'
      },
      {
        url:logo6,
        altText:'Transurban logo'
      },
      {
        url:logo7,
        altText:'Zurich Airport logo'
      },
    ]
  },
  threeColTwoRowComp: {
    title:"Your Engineering Information Available Anywhere, Anytime",
    description: "Empower your maintenance, engineering, project teams and contractors with seamless collaboration and powerful tools that support compliance.",
    items:[
      {
        logoUrl:  businessTimeIcon,
        altText: "Work time icon",
        title:"Slash Admin Time",
        description: "Eliminate time wasted on mundane tasks like finding  up-to-date drawings and documents. Enhance handover drawing quality with automation, identifying missing XREFs, PlotStyles, or Fonts ensuring all crucial details are included."
      },
      {
        logoUrl:  usersDrbIcon,
        altText: "Users icon",
        title:"Effortless Collaboration",
        description: "Create a collaborative single source of truth to efficiently store, markup, and share native CAD and work-in-progress engineering documents. Say goodbye to inaccuracies and version control issues."
      },
      {
        logoUrl:  listCheckSolidIcon,
        altText: "Checklist icon",
        title:"Maintain Compliance",
        description: "Empower your team with the transparency needed to enhance compliance and reinforce governance. Our audit trail, version control, workflows, user and artefact reporting, and data security measures, ensuring your organization stays accountable and compliant."
      }
    ]
  },
  twoColComp:{
    title: "Stay Ahead wth Powerful EDMS Capabilities",
    description: "Learn how our powerful EDMS solutions can help your organization.",
    link: {
      url: '/industries',
      text: "Explore industries using Accruent software"
    },
    items:[
      {
        logo: {
          url: boltIcon,
          alt:'Electricity bolt icon respresenting utilities industry'
        },
        title: "Utilities",
        description: "Accruent’s EDMS solutions empower companies to optimize and maintain their assets throughout their lifecycle, modernize and upgrade their networks, and drive operational resilience.",
        link: {
          url: '/industries/utilities',
          text:'Explore Utilities'
        }
      },
      {
        logo: {
          url: hardHatIcon,
          alt:'Hard hat icon respresenting metals and mining industry'
        },
        title: "Metals and Mining",
        description: "Accruent streamlines compliance with regulations, reduces costs, and optimizes operations. With robust data security, our solutions ensuring data integrity and protect IP.",
        link: {
          url: '/industries/manufacturing/process-manufacturing/metals-mining',
          text:'Explore Metals and Mining'
        }
      },
      /*{
        logo: {
          url: industryWindowsIcon,
          alt:'Industry windows icon representing manufacturing industry'
        },
        title: "Manufacturing",
        description: "Facilitate seamless collaboration within teams and between departments with Accruent EDMS, with real-time monitoring and instant access to the most up-to-date plant and facility data. ",
        link: {
          url: '/industries/manufacturing',
          text:'Explore Manufacturing'
        }
      }*/
    ]
  },
  heroBanner1:{
    title: "Look Under the Hood",
    description:'Access a live one-on-one demo with an Accruent representative, today.',
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: spaceHero2,
      alt: 'An employee walks through the office holding an engineering document.'
    }
  },
  services: {
    title: "Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    items:[
      {
        logo:{
          url: emsLogo,
          alt: "Accruent EMS Logo"
        },
        title: "Desk, Room and Event Scheduling Made Easy",
        description:"Workplace management software simplifies desk, meeting and resource scheduling for your office or campus.",
        link:{
          url: '/products/ems',
          text: "Explore EMS"
        }
      },
      {
        logo:{
          url: lucernexLogo,
          alt: "Accruent Lucernex Logo"
        },
        title: "Real Estate Management Software",
        description:"Market-leading real estate software solutions manage lease, site planning and construction complexities.",
        link:{
          url: '/products/lucernex',
          text: "Explore Lucernex"
        }
      },
      {
        logo:{
          url: redeyeLogo,
          alt: "Accruent Redeye Logo"
        },
        title: "Engineering Document Management Made Easy",
        description:"Engineering document management system streamlines compliance and maximizes compay-wide collaboration.",
        link:{
          url: '/products/redeye',
          text: "Explore RedEye"
        }
      },
      {
        logo:{
          url: mcLogo,
          alt: "Accruent Maintenance Connection Logo"
        },
        title: "Multi-site, multi-industry CMMS software",
        description:"CMMS software reduces downtime, predicts maintenance needs and managers asset and equipment inventory.",
        link:{
          url: '/products/maintenance-connection',
          text: "Explore Maintenance Connection"
        }
      }
    ]
  },
  twoColHeadingComp: {
    title:"Find the right Accruent software for your exact industry",
    items:[
      {
        image:{
          url: twoCol1,
          alt: 'Alt'
        },
        title: "AMIS 360 Space Planning ",
        description:"Use FAMIS CMMS and Space Planning software to gain more control and visibility into the allocation of space across your portfolio.",
        info:[
          {text: "Automate space assignments by organizational department or functional activity"},
          {text: "Uncover additional square footage or discover square footage underutilization"},
          {text: "Identify and resolve capacity bottlenecks"},
          {text: "Integrate your space and facilities data for smarter decision-making"}
        ],
        link:{
          url:'/',
          text:"Access 360 planning brochure"
        }
      },
      {
        image:{
          url: twoCol2,
          alt: 'Alt'
        },
        title: "EMS Space Planning",
        description:"EMS space management software provides key space usage insights that can help you cut costs, improve your bottom line, and maximize flexibility.",
        info:[
          {text: "Track critical metrics like space usage, cancellations, meeting types, service changes, and more"},
          {text: "Lower real estate costs with more than 120 pre-built, flexible utilization reports"},
          {text: "Integrate with building systems like maintenance and HVAC to automate processes and centralize insights"},
        ],
        link:{
          url:'/',
          text:"Explore space planning optimizations"
        }
      }
    ]
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url: resource1,
          altText: "Overcoming Food & Beverage Manufacturing Challenges"
        },
        title:"Overcoming Food & Beverage Manufacturing Challenges",
        description:"Explore some common challenges that food and beverage manufacturers face in 2024 and the role smart technologies play in successfully navigating them.",
        link: '/resources/videos/food-and-beverage-manufacturing-challenges-smart-technologies',
        button:{
          text: 'Video',
        }
      },
      resource2: {
        image:{
          url: resource2,
          altText: 'How Seattle Public Utilities Enhanced Their Engineering Records Efficiency Fivefold'
        },
        title:"How Seattle Public Utilities Enhanced Their Engineering Records Efficiency Fivefold",
        description:"Learn how Seattle Public Utilities overhauled their engineering drawing management system to achieve a 5x increase in their engineering records efficiency.",
        link: '/resources/case-studies/seattle-public-utilities',
        button:{
          text: 'Case Study',
        }
      },
      list:[
        {
          image:{
            url: resource3,
            altText: 'Unlocking the benefits of 3D in engineering and design'
          },
          title:"Unlocking the benefits of 3D in engineering and design",
          link: '/resources/blog-posts/unlocking-benefits-of-3d-in-engineering-and-design',
          button:{
            text: 'Blog',
          }
        },
        {
          image:{
            url: resource4,
            altText: 'Navigating Key Challenges in Food & Beverage Manufacturing in 2024'
          },
          title:"Navigating Key Challenges in Food & Beverage Manufacturing in 2024",
          link: '/resources/white-papers/navigating-2024-food-beverage-manufacturing-challenges',
          button:{
            text: 'White Paper',
          }
        },
        {
          image:{
            url: resource5,
            altText: 'Meridian 3D BIM Visualization'
          },
          title:"Meridian 3D BIM Visualization",
          link: '/resources/brochures/meridian-3d-bim-visualization',
          button:{
            text: 'Brochure',
          }
        },
        // {
        //   image:{
        //     url: resource4,
        //     altText: 'altText'
        //   },
        //   title:"Gain a Competitive Edge: Top Trends in 2024 for the Retail Real Estate Industry",
        //   link: '/',
        //   button:{
        //     text: 'Webinar',
        //   }
        // },
        // {
        //   image:{
        //     url: resource5,
        //     altText: 'altText'
        //   },
        //   title:"TMS: Healthcare CMMS & Asset Management",
        //   link: '/',
        //   button:{
        //     text: 'Brochure',
        //   }
        // }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url:Play1,
            altText: 'Accruent EDMS Solutions'
          },
          title:"Accruent EDMS Solutions",
          description: "Learn how Accruent's EDMS offerings are helping organizations better manage their engineering records.",
          link: "https://accruent.wistia.com/medias/64vuyumkgi",
          wistiaId: '64vuyumkgi'
        },
        {
          logo:{
            url:Play1,
            altText: 'RedEye Overview'
          },
          title:"RedEye Overview",
          description: "See how our modern cloud-based EDMS supports the needs of asset-intensive industries through a collaborative hub that centralizes engineering information.",
          link: "https://accruent.wistia.com/medias/43sp5y45tb",
          wistiaId: '43sp5y45tb'
        },
        {
          logo:{
            url:Play1,
            altText: 'Meridian Overview'
          },
          title:"Meridian Overview",
          description: "Learn how our hybrid EDMS helps organizations manage their technical documents and drawings throughout their lifecycle.",
          link: "https://accruent.wistia.com/medias/kat4ng81ks",
          wistiaId: 'kat4ng81ks'
        }
      ]
    }
  },
  support:{
    logo:{
      url: featureProduct2,
      altText: 'Design Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'Does Accruent’s EDMS make collaboration easier?',
        descList: [
          {
            text: '<span>Accruent’s EDMS allows for <a href="https://www.accruent.com/resources/blog-posts/engineering-document-management-software-connects-disparate-departments">full collaboration among users</a> throughout the organization. Track project updates throughout the approval pipeline, keeping managers fully updated.</span>'
          },
          {
            text: '<span>Keep documentation current so team members can share the best information and easily collaborate.</span>'
          },
          {
            text: '<span>Share drawings, metadata, and other project information with the right people fast. Which sectors rely on engineering document manager software the most?</span>'
          },
          {
            text: '<span>Any business that deals in the realm of construction, energy management, and construction will have a need for a robust EDMS to keep projects organized.</span>'
          },
          {
            text: '<span>From managing product designs to housing critical quality control and safety documents, many businesses will lean on their EDMS to ensure safer workflows.</span>'
          },
          {
            text: '<span>Not only are the day-to-day operations improved, but emergency response times are shortened thanks to having more up-to-date, accessible data.</span>'
          }
        ]
      },
      {
        title: 'Does EDMS support mobile teams?',
        descList: [
          {
            text: '<span>Yes, Accruent’s EDMS software can be utilized by disparate teams whether on desktop or mobile devices. This ensures faster communications and more precise decision making.</span>'
          },
          {
            text: '<span>Meridian streamlines document management across organizations with many locations, keeping information flowing through the entire document lifecycle. Remote teams can collaborate effectively with the flexibility and mobility of a cloud based EDMS solution.</span>'
          }
        ]
      },
      {
        title: 'Is RedEye easy to use? ',
        descList: [
          {
            text: '<span>Yes, Accruent’s EDMS software is extremely user-friendly. With intuitive interfaces and plenty of support documentation, teams will have no issue understanding this system.</span>'
          },
          {
            text: '<span>Accruent offers ongoing support to ensure all users have a solid knowledge base to get the most value out of Redeye.</span>'
          }
        ]
      },
      {
        title: 'What role does Meridian play in project management?',
        descList: [
          {
            text: '<span>By improving document management, organizations will have a better handle on managing projects.</span>'
          },
          {
            text: '<span>A central repository serves a critical role in housing schedules and other project-related documents, ensuring that information is reachable for all stakeholders.</span>'
          },
          {
            text: '<span>Edit, comment, and make changes to documents in real-time. Project management across locations has never been simpler.</span>'
          }
        ]
      },

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
}

const HeroBanner = (heroImages) => {
  return <>
    <div className={styles.heroContainer}>
      <div className={styles.leftWrapper}>
        <h1 className={styles.heroTitle}>
          {data?.heroBanner?.title}
        </h1>
        <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
        <div className={styles.heroBtnWrapper}>
          <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
            <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
            <div className={styles.heroBtn1}>
              <img className={styles.heroBtn1Img} src={Dot}></img>
            </div>
          </a>
          <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
            <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            <img src={arrowRight}></img>
          </a>
        </div>
      </div>
      <div>
        <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/edm-hero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
      </div>
    </div>
  </>
}

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}>
              </p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        {/* <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img> */}
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
            )
            
          }
        </div>       
      </div>
      <div className={styles.featureProductsWrapper}>
        <p className={styles.featureProductsText}>{data?.features?.info?.title}</p>
        <div className={styles.featureProductsLogoList}>
          {
            data?.features?.info?.images?.map((image,index)=>
              <a href={image?.link} key={index} className={styles.featureProductsLogoWrapper}>
                <img src={image?.url} alt={image?.altText} className={styles.featureProductsLogo} loading="lazy"></img>
              </a>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.features?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
        </a>
      </div>
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position} { data?.fullWidthBanner?.companyName ? ", " + data?.fullWidthBanner?.companyName  : ""}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage} src={logo?.url} alt={logo?.altText} loading="lazy"></img>
      )
      }
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

const TwoColComp = () => {
  return (
    <div className={styles.twoColCompContainer}>
      <div className={styles.twoColCompLeftWrapper}>
        <div className={styles.twoColCompTextWrapper}>
          <h2 className={styles.twoColCompTitle}>
              {data?.twoColComp?.title}
          </h2>
          <p className={styles.twoColCompDesc}>
              {data?.twoColComp?.description}
          </p>
        </div>
        <a href={data?.twoColComp?.link?.url} className={styles.twoColCompLinkWrapper}>
          <span className={styles.twoColCompLinkText}>{data?.twoColComp?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
      <div className={styles.twoColCompRightWrapper}>
        {
          data?.twoColComp?.items?.map((item, index)=>
            <div key={index} className={styles.twoColCompCardWrapper}>
              <div className={styles.twoColCompCardTextWrapper}>
                <div className={styles.twoColCompCardLogoWrapper}>
                  <img src={item?.logo?.url} alt={item?.logo?.alt} loading="lazy"></img>
                </div>
                <h3 className={styles.twoColCompCardTitle}>{item?.title}</h3>
                <p className={styles.twoColCompCardDesc}>{item?.description}</p>
              </div>
              <a href={item?.link?.url} className={styles.twoColCompCardLink}>
                <span className={styles.twoColCompCardLinkText}>{item?.link?.text}</span>
                <img src={arrowRightBlue} alt='Right arrow'></img>
              </a>
            </div>
          )
        }
      </div>
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src={arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.heroImage} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const Services = () => {
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceHeadWrapper}>
        <h2 className={styles.serviceTitle}>{data?.services?.title}</h2>
        <p className={styles.serviceDesc}>{data?.services?.description}</p>
      </div>
      <div className={styles.serviceCardContainer}>
        {
          data?.services?.items?.map((item,index)=>
            <div key={index} className={styles.serviceCardWrapper}>
            <div className={styles.serviceCardTextWrapper}>
              <div className={styles.serviceCardLogoWrapper}>
                <img className={styles.serviceCardLogo} src={item?.logo?.url} alt={item?.logo?.alt} loading="lazy"></img>
              </div>
              <div className={styles.serviceCardText}>
                <h3 className={styles.serviceCardTitle}>{item?.title}</h3>
                <p className={styles.serviceCardDesc}>{item?.description}</p>
              </div>
            </div>
            <a href={item?.link?.url} className={styles.serviceCardLinkWrapper}>
              <span className={styles.serviceCardLinkText}>{item?.link?.text}</span>
              <img src={arrowRightBlue} alt='Right arrow'></img>
            </a>
          </div>
          )
        }
      </div>
    </div>
  )
}

const TwoColHeading = () => {
  return (
    <div className={styles.twoColContainer}>
      <h5 className={styles.twoColTitle}>
        {data?.twoColHeadingComp?.title}
      </h5>
      <div className={styles.twoColGridWrapper}>
       { data?.twoColHeadingComp?.items?.map((item, index)=>
          <div key={index} className={styles.twoColGriditemBox}>
            <div>
              <img className={styles.twocColGridImage} src={item?.image?.url} alt={item?.image?.alt} loading="lazy"></img>
              <div className={styles.twocColGridContentWrapper}>
                <div className={styles.twocColGridContent}>
                  <h5 className={styles.twocColGridTitle}>{item?.title}</h5>
                  <p className={styles.twocColGridDesc}>{item?.description}</p>
                    <ul className={styles.twocColGridDescList}>
                      { item?.info?.map((t, index)=>
                        <li key={index}>{t?.text}</li> 
                    )}
                    </ul>
                </div>
              </div>
            </div>
            <div className={styles.twoColGridLink}>
              <a href={item?.link?.url} className={styles.heroBtn2Wrapper}>
                    <span className={styles.heroBtn2Text}>{item?.link?.text}</span>
                    <img src={arrowRight}></img>
              </a>
            </div>
          </div>
      )
        }
      </div>
    </div>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index) =>
                <a key={index} href={item?.link} className={styles.resourceMultiCard}>
                  <div>
                    <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                  <div className={styles.resourceMultiCardTextWrapper}>
                    <p className={styles.resourceMultiCardText}>{item?.title}</p>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

export default function EngineeringDocumentManagementSoftware() {
  const heroImages = useHeroImages();
  const pageDataPath = getPageDataJsonPath('/solutions/engineering-document-management-software')
  const formImages = useFormImages()
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "An engineer on site using a tablet to operate EDM software"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
    return (
      <>
        <SEO
          meta={
            [
              { 'name': 'title', 'content': 'Best Engineering Document Management Software | Accruent' },
              { 'name': 'description', 'content': "Leverage Accruent’s enterprise engineering document management software & empower teams with immediate access to information. Schedule a demo today!" }
            ]
          }
          heroImage={heroImages['staticPages/edm-hero.jpg']}
          preloads= {[pageDataPath]}
          lang="en"
          pageUrl="/solutions/engineering-document-management-software"
          alternatives={[
            {
                "href": "/solutions/engineering-document-management-software",
                "hreflang": "en"
            },
            {
                "href": "/uk/solutions/engineering-document-management-software",
                "hreflang": "en-gb"
            },
            {
                "href": "/solutions/engineering-document-management-software",
                "hreflang": "x-default"
            }
        ]}
        />
          <HeroBanner heroImages={heroImages}/>
          <Features/>
          <FullWidthBanner/>
          <LogoSlider/>
          <ThreeColComp/>
          <TwoColComp/>
          <HeroBanner1/>
          <Services/>
          {/* <TwoColHeading/> */}
          <Resource/>
          <div id="mktoForm">
            <ContentMarketoForm
              entity={contentEntity}
              locale="en"
              pageTitle="EDMS: Best Engineering Document Management Software"
              formFullWidth={false}
            />
          </div>
          <Support/>
      </>
    );
  }
  